import { Injectable } from '@angular/core'
import { LanguageCode } from '@core/enums/language-code.enum'
import { registerLocaleData } from '@angular/common'

//
import localeDe from '@angular/common/locales/de'
import localeDeExtra from '@angular/common/locales/extra/de'
import localePl from '@angular/common/locales/pl'
import localePlExtra from '@angular/common/locales/extra/pl'
import { ActivatedRoute, Router } from '@angular/router'
import { LanguageCodeWithCountry } from '@core/enums/language-code-with-country'
import { Logger } from '@app/general/utils/logger.util'

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	private selectedLanguage?: LanguageCode
	private readonly localstorageKey = 'selected_language'
	private appLanguages: LanguageCode[] = []

	private readonly logger = new Logger(LanguageService.name)

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {
		registerLocaleData(localeDe, LanguageCode.German, localeDeExtra)
		registerLocaleData(localePl, LanguageCode.Polish, localePlExtra)
	}

	/**
	 * Retrieves the language code of the app from the local storage.
	 *
	 * @returns {LanguageCode} The language code of the app.
	 */
	get getLocalStorageLanguage(): LanguageCode {
		return localStorage.getItem(this.localstorageKey) as LanguageCode
	}

	/**
	 * Returns the selected language code.
	 *
	 * @returns {LanguageCode | undefined} The selected language code, or undefined if no language is selected.
	 */
	get getSelectedLanguage(): LanguageCode | undefined {
		return this.selectedLanguage
	}

	/**
	 * Returns the array of acceptable language codes.
	 *
	 * @returns {LanguageCode[]} The array of acceptable language codes.
	 */
	get getAcceptableLanguages(): LanguageCode[] {
		return this.appLanguages
	}

	async getLanguageFromUrl(): Promise<string> {
		const url: string = window.location.href

		this.logger.info(`URL: ${url}`)

		const language: string | null = this.extractLanguage(url)

		this.logger.info(`Detected language: ${language}`)

		if (language) {
			return language
		} else {
			return 'en-US'
		}
	}

	/**
	 * Initializes the languages for the application.
	 * @param {LanguageCode[]} languages - An array of language codes.
	 * @return {void}
	 */
	async initLanguages(languages: LanguageCode[]): Promise<void> {
		this.appLanguages = languages
		const currentLanguage: string = await this.getLanguageFromUrl()
		const localStorageLanguage: string | null = localStorage.getItem(this.localstorageKey)

		this.logger.info(`Current language: ${currentLanguage}`)
		this.logger.info(`Local storage language: ${localStorageLanguage}`)

		if (localStorageLanguage) {
			if (currentLanguage) {
				const currentLanguageCode = currentLanguage.split('-')[0].toLowerCase()
				if (currentLanguageCode) {
					if (localStorageLanguage != currentLanguage) {
						this.setAppLanguage(localStorageLanguage as LanguageCode)
					}
				}
			}
		} else {
			const currentLanguageCode: LanguageCode = currentLanguage.split('-')[0].toLowerCase() as LanguageCode
			this.logger.info(`Current language code: ${currentLanguageCode}`)
			if (currentLanguageCode) {
				this.selectedLanguage = currentLanguageCode as LanguageCode
				this.saveLanguageToLocalStorageLanguage(currentLanguageCode)
			}
		}
	}

	/**
	 * Sets the language of the application.
	 *
	 * @param {LanguageCode} language - The language code specifying the desired language.
	 * @return {void}
	 */
	setAppLanguage(language: LanguageCode): void {
		this.logger.info(`Set language: ${language}`)

		this.saveLanguageToLocalStorageLanguage(language)

		// TODO: Problem z wykrywaniem języka
		const languageCode = this.getLanguageWithCountry(language)

		this.logger.info(`Language with country: ${languageCode}`)

		const parsedURL = window.location.pathname.split('/').filter(segment => segment)
		parsedURL[0] = languageCode

		this.logger.info(`Parsed URL: ${parsedURL}`)

		window.location.pathname = parsedURL.join('/')
	}

	private extractLanguage(url: string): string | null {
		const regex = /\/([a-z]{2}-[A-Z]{2})(?=\/|$)/
		const match = url.match(regex)
		return match ? match[1] : null
	}

	private saveLanguageToLocalStorageLanguage(language: string): void {
		let languageCode: string = LanguageCodeWithCountry.English
		if (language.split('-').length > 1) {
			languageCode = language
		} else {
			languageCode = this.getLanguageWithCountry(language as LanguageCode)
		}

		this.logger.info(`Save language: ${languageCode}`)
		localStorage.setItem(this.localstorageKey, languageCode)
	}

	/**
	 * Get the language code from the browser.
	 *
	 * @returns {LanguageCode} The language code extracted from the browser.
	 */
	private getLanguageFromBrowser(): LanguageCode {
		return window.navigator.language.substring(0, 2) as LanguageCode
	}

	private getLanguageWithCountry(language: LanguageCode): LanguageCodeWithCountry {
		let languageCode: LanguageCodeWithCountry = LanguageCodeWithCountry.English

		if (language == LanguageCode.English) {
			languageCode = LanguageCodeWithCountry.English
		} else if (language == LanguageCode.German) {
			languageCode = LanguageCodeWithCountry.German
		} else if (language == LanguageCode.Polish) {
			languageCode = LanguageCodeWithCountry.Polish
		}

		return languageCode
	}
}
